var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "kneeHurtFilter",
        { ref: "kneeHurtFilter" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getData } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "success" }, on: { click: _vm.handleCreate } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-content" },
        [
          _c("kneeHurtList", {
            ref: "kneeHurtList",
            attrs: { filter: _vm.filter }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }