<template>
  <div class="app-container">
    <kneeHurtFilter ref="kneeHurtFilter">
      <el-button type="primary" @click="getData">查询</el-button>
      <el-button type="success" @click="handleCreate">添加</el-button>
    </kneeHurtFilter>
    <div class="tab-content">
      <kneeHurtList ref="kneeHurtList" :filter="filter" />
    </div>
  </div>
</template>
<script>
import kneeHurtFilter from "./components/kneeHurtFilter";
import kneeHurtList from "./components/kneeHurtList";
export default {
  components: {
    kneeHurtFilter,
    kneeHurtList,
  },
  data() {
    return {
      filter: {},
    };
  },
  methods: {
    getData() {
      let tempfilter = this.$refs.kneeHurtFilter.getfilter();
      this.filter = tempfilter;
    },
    handleCreate() {
      this.$refs.kneeHurtList.handleCreate();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
}
</style>

 

         